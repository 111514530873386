import React from 'react';
import classNames from 'classnames';
import tailwindConfig from 'tailwind.config.js';
import { useState, useEffect } from 'react';

const CarouselPagination = ({ dark, moduleName, current, total, className, ...props }) => {
  const screens = tailwindConfig.theme.screens;
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const setWindowDimension = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('load', setWindowDimension);
    window.addEventListener('resize', setWindowDimension);

    return () => {
      window.removeEventListener('load', setWindowDimension);
      window.removeEventListener('resize', setWindowDimension);
    };
  }, []);

  const calculateLeftPosition = () => {
    if (total <= 3 && windowWidth < parseInt(screens.lg)) {
      return `${(current * 100) / total}%`;
    } else if (total <= 3 && moduleName !== 'QuoteCarouselModule' && moduleName !== 'CardListingVerticalModule') {
      return `${(current * 100) / 2}%`;
    } else {
      return `${(current * 100) / total}%`;
    }
  };

  const calculateWidth = () => {
    if (moduleName === 'CardCarouselGenericModule' && total === 3 && windowWidth > parseInt(screens.lg)) {
      return '100%';
    } else if (total <= 3 && windowWidth < parseInt(screens.lg)) {
      return `${100 / total}%`;
    } else if (total <= 3 && moduleName !== 'QuoteCarouselModule' && moduleName !== 'CardListingVerticalModule') {
      return '50%';
    } else {
      return `${100 / total}%`;
    }
  };

  return (
    <div
      className={classNames('flex items-center w-fit', className)}
      role="progressbar"
      aria-label="Progress"
      {...props}
    >
      <div
        className={classNames(
          `${dark ? 'bg-brand-soft-white/25' : 'bg-black/25'}`,
          'w-[200px] parent-container h-[2px] rounded-[24px] relative bg-black overflow-hidden',
        )}
      >
        <div
          className={classNames(
            `${calculateWidth()} ${dark ? 'bg-brand-soft-white' : 'bg-black'}`,
            ' h-full active-child transition-all duration-[0.7s] absolute rounded-[24px]',
          )}
          style={{ left: calculateLeftPosition(), width: calculateWidth() }}
        />
      </div>
    </div>
  );
};

export default CarouselPagination;
