import { ModuleBase, ResponsiveImage } from '@/components';
import SectionContent from '@/components/cards/SectionContent';

const IconListWithRichTextModule = ({ data }) => {
  const { desktopLayoutAlignment, iconListElements } = data;

  const iconGroups = [];
  const groupSize = 6;

  for (let i = 0; i < iconListElements.length; i += groupSize) {
    const iconGroup = iconListElements.slice(i, i + groupSize);
    iconGroups.push(iconGroup);
  }

  return (
    <ModuleBase
      data={{ ...data, paddingTop: data.paddingTop || 'large', paddingBottom: data.paddingBottom || 'large' }}
      className="font-montserrat color-from-bg"
    >
      <div
        className={`container  flex flex-col gap-8 justify-between 
          ${desktopLayoutAlignment === 'Left' ? 'lg:flex-row' : 'lg:flex-row-reverse '}
          ${iconListElements.length > 6 ? '' : 'lg:justify-between'}
        `}
      >
        <SectionContent
          data={data}
          preHeadingClassName="font-montserrat color-from-bg text-xs not-italic !font-medium leading-normal tracking-quote-reg-mobile uppercase mb-4"
          headingClassName="text-base not-italic color-from-bg font-semibold leading-5 tracking-headline-m-tablet uppercase md:text-xl md:leading-6 md:tracking-quote-reg-mobile"
          descriptionClassName="text-sm not-italic font-normal leading-6"
          className={`items-start 
             [&_.description-wrapper]:mt-4 [&_.button-wrapper]:mt-10 [&_.button-wrapper]:lg:mt-[153px] [&_.secondary-link]:bg-brand-green [&_.secondary-link]:!border-transparent [&_.secondary-link.btn>svg]:!fill-brand-soft-white
             [&_.secondary-link.btn:hover>svg]:!fill-black [&_.secondary-link]:text-brand-soft-white
             ${iconListElements.length > 6 ? 'lg:w-[339px]' : 'lg:w-[600px]'} ${
               !data.preHeading ? '[&_.main-title]:mt-8' : 'mt-0'
             }
             `}
        />

        <div
          className={`main-icon-wrapper flex gap-x-6 ${
            iconListElements.length > 6 ? 'flex-row flex-wrap lg:gap-x-[104px] lg:w-[782px]' : 'flex-col'
          }`}
        >
          {iconGroups.map((group, groupIndex) => (
            <div
              key={groupIndex}
              className={`icon-item flex flex-col w-full mb-6 last:mb-0  lg:mb-10
              ${iconListElements.length > 6 ? 'md:w-[48%] lg:w-[339px]' : 'w-full lg:w-[400px]'}`}
            >
              {group.map((iconItem, iconIndex) => (
                <div
                  className={`icon-wrapper w-full flex items-center gap-3 py-4 border-b color-from-bg border-opacity-10 md:last:pb-0 last:border-none
                            
                  `}
                  key={iconIndex}
                >
                  <div className={`icon aspect-square w-6 h-6 `}>
                    <ResponsiveImage
                      image={iconItem.logo}
                      widths={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
                      heights={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
                      image-class="h-full w-full object-cover"
                      className=" h-full w-full object-cover"
                    />
                  </div>

                  <div className="text w-[81%]">
                    <p className="text-base not-italic font-normal leading-6.5 ">{iconItem.text}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </ModuleBase>
  );
};

export default IconListWithRichTextModule;
