import React, { useState, useRef, useEffect } from 'react';
import { ModuleBase, EntityCard } from 'components';
import Flicking from '@egjs/react-flicking';
import CarouselPagination from '@/components/general/CarouselPagination';
import { fixColor, isColorDark } from '@/utils/color-utils.js';
import tailwindConfig from '../tailwind.config.js';
import '@egjs/react-flicking/dist/flicking.css';
import { HeadingTag, Link } from '@/components/index.js';
import ArrowRight from 'assets/arrowRight.svg';
import classNames from 'classnames';

const CardCarouselGenericModule = ({ data }) => {
  const { cardRow } = data || {};
  const [activeIndex, setActiveIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  const screens = tailwindConfig.theme.screens;
  const [itemNumber, setItemNumber] = useState(0);
  const carousel = useRef();
  const dark = isColorDark(fixColor(data?.backgroundColour));

  const willChange = (e) => {
    setActiveIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < parseInt(screens.md)) {
        setItemNumber(1);
      } else if (window.innerWidth >= parseInt(screens.md) && window.innerWidth < parseInt(screens.lg)) {
        setItemNumber(2);
      } else {
        setItemNumber(3);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screens.md, screens.lg]);

  return (
    <ModuleBase data={data} className={``}>
      {(data.headingTitle.heading.length > 0 || data.description.length > 0 || data.primaryCta) && (
        <div className="content-wrapper container lg:px-[108px] mb-6 pt-12 md:pt-14 lg:pb-4 font-montserrat flex flex-col gap-8 lg:flex-row lg:gap-0 lg:justify-between">
          <div className="copy lg:max-w-[808px]">
            {data.headingTitle && (
              <HeadingTag
                data={data.headingTitle}
                className="color-from-bg mb-4 font-semibold uppercase text-base leading-5 tracking-headline-m-tablet md:text-xl md:leading-6 md:tracking-title-m"
              />
            )}
            {data.description && (
              <div
                dangerouslySetInnerHTML={{ __html: data.description }}
                className="color-from-bg text-sm leading-6 font-normal opacity-75"
              />
            )}
          </div>
          {data.primaryCta && (
            <Link link={data.primaryCta} className="btn primary w-full md:w-fit color-from-bg lg:self-end">
              {data.primaryCta.text} <ArrowRight className="!ml-6" />
            </Link>
          )}
        </div>
      )}
      <div className="card-carousel-generic-wrapper max-w-[390px] md:max-w-[768px] lg:max-w-[1440px] mx-auto px-6 md:px-10 lg:px-[108px]">
        {cardRow.map((row, rIndex) => (
          <div key={rIndex} className="w-full">
            <Flicking
              ref={carousel}
              align="prev"
              panelsPerView={
                row.moduleName === 'ArticleCardRow' ||
                row.moduleName === 'ResortCardRow' ||
                row.moduleName === 'RestaurantCardRowModel'
                  ? itemNumber
                  : ''
              }
              useResizeObserver={true}
              horizontal={true}
              preventDefaultOnDrag
              circular={true}
              onReady={onReady}
              onWillChange={willChange}
              circularFallback="bound"
              className=""
            >
              {row.props.cards.map((card, index) => (
                <div key={index} className="flex mr-6 [&_.article-card]:w-full">
                  <EntityCard data={card} className={`w-[342px] md:w-[332px] xl:w-[392px] akarmi `} />
                </div>
              ))}
            </Flicking>

            <CarouselPagination
              dark={dark}
              current={activeIndex}
              total={panelCount}
              className={classNames('mx-auto lg:mx-0 lg:ml-auto mt-12 md:mt-16', {
                block: panelCount >= 3,
                'lg:hidden': panelCount <= 3,
              })}
              moduleName={data.moduleName}
            />
          </div>
        ))}
      </div>
    </ModuleBase>
  );
};
export default CardCarouselGenericModule;
