import { ModuleBase } from '@/components';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const allLocations = ['belek', 'bodrum', 'kemer'];

const WeatherModule = ({ data }) => {
  const { location } = data;
  const [forecastData, setForecastData] = useState(null || []);
  const router = useRouter();

  useEffect(() => {
    const fetchData = async (location) => {
      const locationString = location === 'caja' ? 'Göltürkbükü' : location;

      const data = await fetch(`/weather?location=${locationString}&lang=${router.locale.toLowerCase()}`);

      if (data && data.ok) {
        const wData = await data.json();

        return wData;
      }
    };

    if (location !== 'all') {
      fetchData(location).then((weatherData) => {
        setForecastData(weatherData);
      });
    }

    if (location === 'all') {
      allLocations.forEach(async (location) => {
        const wData = await fetchData(location);
        setForecastData((prev) => [...prev, wData]);
      });
    }
  }, [location, router]);

  if (!forecastData || forecastData.length === 0) return null;

  return (
    <ModuleBase data={data} key={data.moduleId}>
      <div className="container">
        {location !== 'all' && (
          <WeatherWidget key={data.moduleId} forecast={forecastData} locale={router.locale} className="mx-auto" />
        )}
        {location === 'all' && (
          <div className="flex flex-col items-center lg:justify-center lg:flex-row lg:flex-wrap gap-6">
            {forecastData.map((forecast, index) => (
              <WeatherWidget
                key={`weatherWidget-${index}-${data.moduleId}`}
                forecast={forecast}
                locale={router.locale}
              />
            ))}
          </div>
        )}
      </div>
    </ModuleBase>
  );
};

export default WeatherModule;

const WeatherWidget = ({ forecast, locale, className }) => {
  const { t } = useTranslation('common');

  function groupDuplicates(array, compareF) {
    return array.reduce((acc, item) => {
      const existingGroup = acc.find((g) => compareF(g[0], item));
      if (existingGroup) {
        existingGroup.push(item);
      } else {
        acc.push([item]);
      }
      return acc;
    }, []);
  }

  return (
    <div
      className={classNames(
        'rounded-lg font-montserrat p-2 pt-6 w-full sm:w-[560px] xxl:w-[600px] flex flex-col border gap-4 text-brand-soft-white bg-background-spotlight border-black/25',
        className,
      )}
    >
      <div className="upper flex justify-between">
        <div className="flex flex-col items-center justify-between">
          <div className="flex items-start sm:pl-4">
            <div className="flex items-center sm:gap-16">
              <div className="flex flex-col gap-2">
                <p className="font-montserrat font-semibold text-sm leading-normal tracking-title-s uppercase">{`${forecast.city.name === 'Göltürkbükü' ? 'Caja by Maxx' : forecast.city.name}, ${forecast.city.country}`}</p>
                <p className="w-full flex flex-col text-xs leading-5 font-montserrat gap-0.5">
                  <span>
                    {new Date().toLocaleDateString(locale, {
                      weekday: 'long',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                  <span>
                    {new Date().toLocaleTimeString(locale, {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </span>
                </p>
              </div>
              <div className="flex items-end sm:gap-[33px]">
                <div className="size-9 sm:size-16 relative">
                  <Image
                    fill
                    src={`https://openweathermap.org/img/wn/${forecast.list[0].weather[0].icon}@2x.png`}
                    alt={forecast.list[0].weather[0].description}
                    className="size-full object-cover object-center"
                  />
                </div>
                <div className="flex items-start font-didot">
                  <span className="text-4xl leading-10 -tracking-[0.01em] sm:text-[64px] sm:leading-[70px] italic sm:-tracking-headline-l">
                    {forecast.list[0].main.temp.toFixed()}
                  </span>
                  <span className="font-montserrat text-base ml-[11px] ledaing-[26px] w-[19px] h-[26px]">&#176;C</span>
                </div>
              </div>
            </div>
          </div>
          <p className="capitalize font-semibold">{forecast.list[0].weather[0].description}</p>
        </div>
        <div className="flex flex-col gap-1 items-end">
          <div
            className={classNames('rounded-full w-fit gap-1 py-0.5 pl-1 pr-0.5 flex items-center text-xs font-medium ')}
          >
            <span>{t('weather.$humidity')}</span>{' '}
            <span className={classNames('rounded-full py-0.5 px-1 bg-black/10')}>
              {forecast.list[0].main.humidity}%
            </span>
          </div>
          <div
            className={classNames('rounded-full w-fit gap-1 py-0.5 pl-1 pr-0.5 flex items-center text-xs font-medium ')}
          >
            <span>{t('weather.$wind')}</span>{' '}
            <span className={classNames('rounded-full py-0.5 px-1 bg-black/10')}>
              {forecast.list[0].wind.speed.toFixed(1)} m/s
            </span>
          </div>
          <div
            className={classNames('rounded-full w-fit gap-1 py-0.5 pl-1 pr-0.5 flex items-center text-xs font-medium ')}
          >
            <span>{t('weather.$highTemp')}</span>{' '}
            <span className={classNames('rounded-full py-0.5 px-1 bg-black/10')}>
              {forecast.list[0].main.temp_max.toFixed(1)}&#176;
            </span>
          </div>
          <div
            className={classNames('rounded-full w-fit gap-1 py-0.5 pl-1 pr-0.5 flex items-center text-xs font-medium ')}
          >
            <span>{t('weather.$lowTemp')}</span>{' '}
            <span className={classNames('rounded-full py-0.5 px-1 bg-black/10')}>
              {forecast.list[0].main.temp_min.toFixed(1)}&#176;
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 sm:flex gap-2">
        {groupDuplicates(forecast.list, (a, b) => a.dt_txt.split(' ')[0] === b.dt_txt.split(' ')[0]).map(
          (day, index) => {
            if (index === 0) return;

            return <DayForecast key={index} index={index} day={day} />;
          },
        )}
      </div>
    </div>
  );
};

const DayForecast = ({ day, index }) => {
  const router = useRouter();
  let min = day[0].main.temp_min.toFixed(),
    max = day[0].main.temp_max.toFixed(),
    currentTemp,
    icon,
    weekday;

  weekday = new Date(day[0].dt_txt).toLocaleDateString(router.locale, {
    weekday: 'short',
  });

  day.map((forecast) => {
    if (forecast.main.temp_min < min) {
      min = forecast.main.temp_min.toFixed();
    }

    if (forecast.main.temp_max > max) {
      max = forecast.main.temp_max.toFixed();
    }

    if (new Date(forecast.dt_txt).getHours() <= new Date().getHours()) {
      currentTemp = forecast.main.temp.toFixed();
      icon = { src: forecast.weather[0].icon, alt: forecast.weather[0].description };
    }
  });

  return (
    <div
      className={classNames(
        'weather-forecast text-xs leading-5 flex gap-3 justify-center font-semibold w-full py-[13px] rounded-xl bg-black/10',
        index > 3 ? 'hidden sm:flex' : '',
      )}
    >
      <div className="flex flex-col items-center">
        <p className=" font-bold">{weekday}</p>
        <div className="flex items-center">
          <p>{currentTemp}&#176;</p>
          <div className="size-5 relative">
            <Image
              fill
              src={`https://openweathermap.org/img/wn/${icon?.src}@2x.png`}
              alt={icon?.alt}
              className="size-full object-cover object-center"
            />
          </div>
        </div>
      </div>
      <div className="font-normal">
        <p>{min}&#176;</p>

        <p>{max}&#176;</p>
      </div>
    </div>
  );
};
