import React from 'react';
import { FormControl } from '@/components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const LanguageSelect = ({ metadata, className, onChange, ...props }) => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const languageChange = (event) => {
    const lang = metadata.languages.find((item) => item.language === event.target.value);
    router.push(lang.languageUrl, lang.languageUrl, { locale: lang.language === 'en-us' ? 'en-US' : lang.language });
    onChange && onChange(event);
  };

  return (
    <FormControl
      aria-label={t('general.$languageSwitcher')}
      type="select"
      required
      name="language"
      value={router.locale.toLowerCase()}
      className={classNames(
        'font-montserrat text-sm not-italic font-medium leading-5 tracking-button-reg uppercase ' +
          '[&_.main-button]:bg-transparent [&_.main-button]:border-transparent ' +
          '[&_.popover-title]:font-montserrat [&_.popover-title]:text-sm [&_.popover-title]:not-italic [&_.popover-title]:font-medium [&_.popover-title]:leading-5 [&_.popover-title]:tracking-button-reg [&_.popover-title]:uppercase ' +
          '[&_.main-button_svg]:stroke-brand-soft-white [&_.main-button_svg]:ml-2 ' +
          '[&_.content]:rounded-sm [&_.content_ul]:py-0 [&_.content_.option]:border-b [&_.content_.option]:border-b-black/25 ' +
          '[&_.content_.option]:text-center [&_.content_.option]:p-1.5 [&_.content_.option]:text-black ',
        className,
      )}
      options={metadata.languages.map((item) => ({
        label: t(`language.$${item.language.replace('-', '')}`),
        value: item.language,
      }))}
      onChange={languageChange}
      {...props}
    />
  );
};

export default LanguageSelect;
