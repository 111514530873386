import { ModuleBase, Link, HeadingTag, ResponsiveImage } from 'components';
import ArrowRight from 'assets/arrowRight.svg';
// import { isColorDark } from 'utils';

const CTabreakWithLogoModule = ({ data }) => {
  return (
    <ModuleBase data={data} className="font-didot">
      <div className="container main-wrapper flex flex-col items-center gap-12 lg:gap-[267px] lg:flex-row md:justify-between">
        <div className="logo w-full  max-w-[213px] h-[213px] md:max-w-[213px] md:h-[213px]">
          <ResponsiveImage
            image={data.image}
            widths={{ xs: 426, sm: 426, md: 426, lg: 426, xl: 426, xxl: 426 }}
            heights={{ xs: 426, sm: 426, md: 426, lg: 426, xl: 426, xxl: 426 }}
            image-class="h-full w-full object-cover "
            className=" h-full w-full object-cover"
          />
        </div>

        <div className="w-full md:w-full flex flex-col">
          <div className="flex flex-col gap-8 mb-12 lg:gap-8">
            {data.headingTitle && (
              <HeadingTag
                data={data.headingTitle}
                className="font-didot color-from-bg whitespace-pre-wrap text-title-l not-italic font-normal leading-10 -tracking-headline-m-mobile w-fit mx-auto
                            text-center sm:text-left sm:w-[468px]
                            md:text-4xl md:leading-[44px] md:-tracking-headline-l-mobile 
                            lg:mx-0 lg:w-fit lg:text-title-xl lg:leading-12 lg:-tracking-headline-s [&_.indented]:ml-0 [&_.indented]:sm:ml-[44px]"
              ></HeadingTag>
            )}

            {data.text && (
              <div
                dangerouslySetInnerHTML={{ __html: data.text }}
                className="font-montserrat text-sm not-italic font-normal leading-6 color-from-bg"
              ></div>
            )}
          </div>

          {data.primaryCTA && (
            <div className="button-wrapper flex flex-row lg:items-center">
              <Link
                className="btn primary first-link w-full md:w-fit md:mx-auto lg:mx-0 color-from-bg"
                link={data.primaryCTA}
              >
                <span className="font-montserrat text-sm not-italic font-medium leading-5 tracking-button-reg uppercase">
                  {data.primaryCTA.text}
                </span>
                <ArrowRight className="!ml-6" role="presentation" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </ModuleBase>
  );
};

export default CTabreakWithLogoModule;
